// @flow
import React, { useRef, useEffect, forwardRef, useState, memo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
import classNames from "classnames";
import { FaUsersCog } from "react-icons/fa";
// components
import Pagination from "./Pagination";
import { useSearchParams } from "react-router-dom";
import useSearchParamsHook from "../../hooks/useSearchParamsHook";
import { useTranslation } from "react-i18next";

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchBoxClass,
  isServerPagination,
}) => {
  const { t } = useTranslation();
  const { allSearchParams, setParams } = useSearchParamsHook();
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div class="d-flex align-items-center justify-content-between bg-primary text-white p-2 rounded shadow-sm mb-4">
      <div class="d-flex align-items-center">
        <FaUsersCog size={25} />

        <span class="fw-normal fs-5">{t("total records:")}</span>
        <span class="ms-2 px-2 fs-5 fw-normal">
          {count} {""} records
        </span>
      </div>

      {/* <!-- Search Field --> */}
      <div className={classNames(searchBoxClass)}>
        <span className="d-flex align-items-center">
          <input
            type="search"
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);

              if (!e.target.value.length) {
                setParams({
                  ...allSearchParams,
                  q: "",
                });
              }

              if (!isServerPagination) {
                onChange(e.target.value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setParams({
                  ...allSearchParams,
                  q: value,
                });
              }
            }}
            placeholder={
              isServerPagination
                ? t("press enter key to search")
                : `Search ${count} records...`
            }
            className="form-control w-auto ms-1"
          />
        </span>
      </div>
    </div>
  );
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          {...rest}
        />
        <label htmlFor="form-check-input" className="form-check-label"></label>
      </div>
    </>
  );
});

const Table = (props) => {
  const isSearchable = props["isSearchable"] || false;
  const isSortable = props["isSortable"] || false;
  const isSelectable = props["isSelectable"] || false;
  const isExpandable = props["isExpandable"] || false;
  const isLocalStorage = props["isLocalStorage"] || false;
  const pagination = props["pagination"] || false;
  const serverPaginationInfo = props["serverPaginationInfo"] || false;
  const isServerPagination = props["isServerPagination"] || false;

  const dataTable = useTable(
    {
      columns: props["columns"],
      data: props["data"],
      initialState: { pageSize: props["pageSize"] || 10 },
    },
    isSearchable && useGlobalFilter,
    isSortable && useSortBy,
    isExpandable && useExpanded,
    pagination && !isServerPagination && usePagination,
    isSelectable && useRowSelect,
    (hooks) => {
      isSelectable &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);

      isExpandable &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            // Build our expander column
            id: "expander", // Make sure it has an ID
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
              <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? "-" : "+"}
              </span>
            ),
            Cell: ({ row }) =>
              // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
              // to build the toggle for expanding a row
              row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps({
                    style: {
                      // We can even use the row.depth property
                      // and paddingLeft to indicate the depth
                      // of the row
                      paddingLeft: `${row.depth * 2}rem`,
                    },
                  })}
                >
                  {row.isExpanded ? "-" : "+"}
                </span>
              ) : null,
          },
          ...columns,
        ]);
    }
  );

  let rows =
    pagination && !isServerPagination ? dataTable.page : dataTable.rows;

  return (
    <>
      {isSearchable && (
        <GlobalFilter
          preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
          globalFilter={dataTable.state.globalFilter}
          setGlobalFilter={dataTable.setGlobalFilter}
          searchBoxClass={props["searchBoxClass"]}
          isServerPagination={isServerPagination}
        />
      )}

      <div className="table-responsive">
        <table
          {...dataTable.getTableProps()}
          className={classNames(
            "table table-centered react-table",
            props["tableClass"]
          )}
        >
          <thead className={props["theadClass"]}>
            {dataTable.headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(
                      column.sort && column.getSortByToggleProps()
                    )}
                    className={classNames({
                      sorting_desc: column.isSortedDesc === true,
                      sorting_asc: column.isSortedDesc === false,
                      sortable: column.sort === true,
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...dataTable.getTableBodyProps()}>
            {rows.map((row) => {
              dataTable.prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{
                          width: cell.column.width
                            ? `${cell.column.width}px`
                            : "auto",
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {pagination && (
        <Pagination
          link={props.link}
          tableProps={dataTable}
          sizePerPageList={props["sizePerPageList"]}
          isLocalStorage={isLocalStorage}
          serverPaginationInfo={serverPaginationInfo}
          isServerPagination={isServerPagination}
        />
      )}
    </>
  );
};

export default Table;
