import { useSearchParams } from "react-router-dom";
import { getAllQueryParams } from "../utils/getAllQueryParams";
import { useEffect } from "react";

const useSearchParamsHook = () => {
  // search params
  const [params, setParams] = useSearchParams();

  const allSearchParams = getAllQueryParams(params);

  const initialQuery = {
    page: allSearchParams.page || 1,
    limit: allSearchParams.limit || 50,
    ...allSearchParams,
  };

  useEffect(() => {
    setParams({
      ...allSearchParams,
      ...initialQuery,
    });
  }, []);

  return { initialQuery, setParams, allSearchParams };
};

export default useSearchParamsHook;
