//External Lib Import
import { lazy, Suspense } from "react";

//Internal Lib Import
import LazyLoader from "../components/LazyLoader";
import Message from "../screens/private/shunno-landing/Message/Message";

const Home = lazy(() => import("../screens/private/Home"));
const Dashboard = lazy(() => import("../screens/private/Dashboard"));
const Role = lazy(() => import("../screens/private/Role/Role"));
const Priority = lazy(() => import("../screens/private/Priority/Priority"));
const Notification = lazy(() =>
  import("../screens/private/Notification/Notification")
);
const Department = lazy(() =>
  import("../screens/private/Department/Department")
);
const Agent = lazy(() => import("../screens/private/Agent/Agent"));
const Support = lazy(() => import("../screens/private/Support/Support"));
const AssignSupport = lazy(() =>
  import("../screens/private/Support/AssignSupport")
);

const CreateUpdateRole = lazy(() =>
  import("../screens/private/Role/CreateUpdateRole")
);
const CreateUpdateNotification = lazy(() =>
  import("../screens/private/Notification/CreateUpdateNotification")
);
const CreateUpdatePriority = lazy(() =>
  import("../screens/private/Priority/CreateUpdatePriority")
);
const CreateUpdateDepartment = lazy(() =>
  import("../screens/private/Department/CreateUpdateDepartment")
);
const CreateUpdateAgent = lazy(() =>
  import("../screens/private/Agent/CreateUpdateAgent")
);
const CreateUpdateSupport = lazy(() =>
  import("../screens/private/Support/CreateUpdateSupport")
);
const ReplySupport = lazy(() =>
  import("../screens/private/Support/ReplySupport")
);

const LazyLoading = ({ children }) => {
  return <Suspense fallback={<LazyLoader />}>{children}</Suspense>;
};

const privateRoutes = [
  {
    path: "/",
    element: (
      <LazyLoading>
        <Home />
      </LazyLoading>
    ),
    roles: ["all"],
    accessPermission: null,
  },
  {
    path: "/departments",
    element: (
      <LazyLoading>
        <Department />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/department-create-update",
    element: (
      <LazyLoading>
        <CreateUpdateDepartment />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },

  {
    path: "/priority",
    element: (
      <LazyLoading>
        <Priority />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/priority-create-update",
    element: (
      <LazyLoading>
        <CreateUpdatePriority />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/department",
    element: (
      <LazyLoading>
        <Department />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/department-create-update",
    element: (
      <LazyLoading>
        <CreateUpdateDepartment />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/agents",
    element: (
      <LazyLoading>
        <Agent />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/agent-create-update",
    element: (
      <LazyLoading>
        <CreateUpdateAgent />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/notification",
    element: (
      <LazyLoading>
        <Notification />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/notification-create-update",
    element: (
      <LazyLoading>
        <CreateUpdateNotification />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  // {
  //   path: '/role',
  //   element: (
  //     <LazyLoading>
  //       <Role />
  //     </LazyLoading>
  //   ),
  //   roles: ['all'],
  //   accessPermission: null,
  // },
  {
    path: "/role-create-update",
    element: (
      <LazyLoading>
        <CreateUpdateRole />
      </LazyLoading>
    ),
    roles: ["proprietor"],
    accessPermission: null,
  },
  {
    path: "/dashboard",
    element: (
      <LazyLoading>
        <Dashboard />
      </LazyLoading>
    ),
    roles: ["all"],
    accessPermission: null,
  },
  {
    path: "supports",
    element: (
      <LazyLoading>
        <Support />
      </LazyLoading>
    ),
    roles: ["all"],
    accessPermission: null,
  },
  {
    path: "assign-supports",
    element: (
      <LazyLoading>
        <AssignSupport />
      </LazyLoading>
    ),
    roles: ["all"],
    accessPermission: null,
  },
  {
    path: "support-create-update",
    element: (
      <LazyLoading>
        <CreateUpdateSupport />
      </LazyLoading>
    ),
    roles: ["all"],
    accessPermission: null,
  },
  {
    path: "support-reply",
    element: (
      <LazyLoading>
        <ReplySupport />
      </LazyLoading>
    ),
    roles: ["all"],
    accessPermission: null,
  },
  {
    path: "messages",
    element: (
      <LazyLoading>
        <Message />
      </LazyLoading>
    ),
    roles: ["all"],
    accessPermission: null,
  },
];

export default privateRoutes;
