// @flow
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { GrPrevious, GrNext } from "react-icons/gr";
import { getAllQueryParams } from "./../../utils/getAllQueryParams";

const Pagination = ({
  tableProps,
  sizePerPageList,
  link,
  isLocalStorage,
  serverPaginationInfo,
  isServerPagination,
}) => {
  /**
   * pagination count , index
   */
  const [pageCount, setPageCount] = useState(tableProps.pageCount);
  const [pageIndex, setPageIndex] = useState(tableProps.state.pageIndex);

  // search params
  const [params, setParam] = useSearchParams();

  const allSearchParams = getAllQueryParams(params);

  const handlePageSizeChange = (e) => {
    if (isServerPagination) {
      setParam((prev) => {
        prev.set("limit", Number(e.target.value));
        prev.set("page", 1);

        return prev;
      });
    } else {
      tableProps.setPageSize(Number(e.target.value));
      if (isLocalStorage) {
        localStorage.setItem("pageCount", e.target.value);
      }
    }
  };

  useEffect(() => {
    setPageCount(tableProps.pageCount);
    setPageIndex(tableProps.state.pageIndex);
  }, [tableProps.pageCount, tableProps.state.pageIndex]);

  /**
   * get filter pages
   */
  const filterPages = useCallback(
    (visiblePages, totalPages) => {
      return visiblePages.filter((page) => page > 0 && page <= totalPages);
    },
    [pageCount, allSearchParams?.limit, allSearchParams?.page]
  );

  /**
   * Handle visible pages logic
   */
  const getVisiblePages = useCallback((page, total) => {
    if (!total || total < 1) return [];

    // If the total number of pages is 5 or less, display all pages
    if (total <= 5) {
      return [...Array(total)].map((_, i) => i + 1);
    }

    // Display the first 3-4 pages
    if (page <= 3) {
      return [1, 2, 3, 4, "...", total];
    }

    // Display the last 3-4 pages
    if (page >= total - 2) {
      return [1, "...", total - 3, total - 2, total - 1, total];
    }

    // Display middle page numbers
    return [1, "...", page - 1, page, page + 1, "...", total];
  }, []);

  const [visiblePages, setVisiblePages] = useState(
    getVisiblePages(
      0,
      isServerPagination ? serverPaginationInfo?.totalPage : pageCount
    )
  );

  /**
   * handle page change
   * @param page - current page
   * @returns
   */
  const changePage = (page) => {
    if (isServerPagination) {
      setParam((prev) => {
        prev.set("page", Number(page));

        return prev;
      });
    } else {
      const activePage = pageIndex + 1;

      if (page === activePage) {
        return;
      }

      const visiblePages = getVisiblePages(page, pageCount);
      setVisiblePages(filterPages(visiblePages, pageCount));

      tableProps.gotoPage(page - 1);
    }
  };

  const activePage = useMemo(() => {
    return Number(
      isServerPagination ? allSearchParams?.page || 1 : pageIndex + 1
    );
  }, [allSearchParams?.page, pageIndex]);

  useEffect(() => {
    const totalPages = isServerPagination
      ? serverPaginationInfo?.totalPage
      : pageCount;
    setVisiblePages(getVisiblePages(activePage, totalPages));
  }, [activePage, pageCount, serverPaginationInfo, getVisiblePages]);

  return (
    <div className="d-lg-flex align-items-center text-center pb-1">
      {Array.isArray(sizePerPageList) && (
        <div className="d-inline-block me-3">
          <label className="me-1">Display :</label>
          <select
            value={
              isServerPagination
                ? params.get("limit") || 50
                : tableProps.state.pageSize
            }
            onChange={handlePageSizeChange}
            className="form-select d-inline-block w-auto"
          >
            {(sizePerPageList || []).map((pageSize, index) => {
              return (
                <option key={index} value={pageSize.value}>
                  {pageSize.text}
                </option>
              );
            })}
          </select>
        </div>
      )}

      <span className="me-3">
        Page{" "}
        <strong>
          {isServerPagination ? allSearchParams?.page || 1 : pageIndex + 1} of{" "}
          {isServerPagination
            ? serverPaginationInfo?.totalItems
            : tableProps.pageOptions?.length}
        </strong>{" "}
      </span>

      <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2">
        <label>Go to page : </label>
        <input
          type="number"
          value={
            isServerPagination ? allSearchParams?.page || 1 : pageIndex + 1
          }
          min="1"
          onChange={(e) => {
            if (isServerPagination) {
              setParam((prev) => {
                prev.set("page", allSearchParams?.page || 1);

                return prev;
              });
            } else {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              tableProps.gotoPage(page);
              setPageIndex(tableProps.state.pageIndex);
            }
          }}
          className="form-control w-25 ms-1 d-inline-block"
        />
      </span>

      <ul className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
        <li
          key="prevpage"
          className={classNames("page-item", "paginate_button", "previous", {
            disabled: activePage === 1,
          })}
          onClick={() => {
            if (activePage <= 1) return;
            changePage(activePage - 1);
          }}
        >
          <button className="page-link">
            <GrPrevious />
          </button>
        </li>

        {visiblePages.map((page) => {
          return (
            <li
              key={page}
              className={classNames(
                "page-item",
                "d-none",
                "d-xl-inline-block",
                {
                  active: Number(activePage) === Number(page),
                  disabled: page === "...",
                }
              )}
            >
              <button onClick={(e) => changePage(page)} className="page-link">
                {page}
              </button>
            </li>
          );
        })}

        <li
          key="nextpage"
          className={classNames("page-item", "paginate_button", "next", {
            disabled: activePage === tableProps.pageCount,
          })}
          onClick={() => {
            if (isServerPagination) {
              changePage(Number(activePage) + 1);
            } else {
              if (activePage === tableProps.pageCount) return;
              changePage(activePage + 1);
            }
          }}
        >
          <button className="page-link">
            <GrNext />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
