//External lib imports
import {
  Button,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

//Internal lib imports
import { useState } from "react";
import { GrView } from "react-icons/gr";
import Table from "../../../../components/Table/Table";
import Layout from "../../../../layout/Layout";
import { useMessageListQuery } from "../../../../redux/services/messageService";
import DateFormatter from "../../../../utils/DateFormatter";
import MessageModal from "./MessageModal";

const Message = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useMessageListQuery();
  const [messageDetails, setMessageDetails] = useState(null);
  const [isShow, invokeModal] = useState(false);

  const detailsMessage = (message) => {
    setMessageDetails(message);
    invokeModal(true);
  };

  const columns = [
    {
      Header: "#",
      accessor: (_, index) => index + 1,
      sort: true,
    },
    {
      Header: t("name"),
      accessor: (d) => d?.name,
      sort: true,
    },
    {
      Header: t("mobile"),
      accessor: (d) => d?.mobile,
      sort: true,
    },
    {
      Header: t("email"),
      accessor: (d) => d?.email,
      sort: true,
    },
    {
      Header: t("subject"),
      accessor: (d) => d?.subject,
      sort: true,
    },
    {
      Header: t("create date"),
      accessor: (d) => <DateFormatter date={d?.createdAt} />,
      sort: true,
    },
    {
      Header: t("action"),
      accessor: (d) => {
        return (
          <div className="bodySmall">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">View</Tooltip>}
            >
              <Button
                variant="primary"
                style={{ padding: "5px 10px" }}
                className="me-1"
                onClick={() => detailsMessage(d)}
              >
                <GrView />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: data?.length,
    },
  ];

  return (
    <Layout>
      <Container fluid="true" className="p-2">
        <Card>
          <Card.Body>
            <Row>
              <Col className="d-flex justify-content-between p-2" sm={12}>
                <h5>{t("messages")}</h5>
              </Col>
              <Col sm={12}>
                {isLoading ? (
                  <Spinner size="lg" variant="primary" />
                ) : data?.length ? (
                  <Table
                    columns={columns}
                    data={data}
                    pageSize={5}
                    sizePerPageList={sizePerPageList}
                    isSortable={true}
                    pagination={true}
                    isSearchable={true}
                  />
                ) : (
                  t("no data found")
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <MessageModal
        message={messageDetails}
        isShow={isShow}
        invokeModal={invokeModal}
      />
    </Layout>
  );
};

export default Message;
