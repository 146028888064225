import React from "react";
import { Button, Modal } from "react-bootstrap";

function MessageModal({ message, isShow, invokeModal }) {
  const initModal = () => {
    return invokeModal(!isShow);
  };
  return (
    <>
      <Modal show={isShow}>
        <Modal.Header closeButton onClick={initModal}>
          <Modal.Title>Name: {message?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message?.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={initModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default MessageModal;
